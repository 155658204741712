import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Header from "../../components/Header"

const bgImage = "/header.jpg"

export default () => (
	<Layout>
		<SEO pageTitle="Lash treatments" />
		<Header pageTitle="Lash treatments" bgImage={bgImage} />
		<section className="intro-text">
			<div className="container">
				<p>Lash treatments intro.</p>
			</div>
		</section>
		<section className="grey-bg">
			<div className="container">
				<h2>LVL Lash Lift &ndash; &pound;42</h2>
				<p>LVL lash lift is the revolutionary lash treatment that creates masses of length, volume and lift with your own natural lashes. No mascara. No extensions. No maintenance.</p>
				<p>It&rsquo;s a great alternative to lash extensions and the perfect way to enhance what you already have, whether your lashes are long, short, thick or stubborn. This treatment will create the look of longer, fuller lashes that make your eyes appear more open and youthful as well as saving time in your morning routine. So, if you&rsquo;re looking for a long-lasting, low maintenance, natural looking eyelash enhancement then Nouveau Lashes LVL lash lift is for you. Time taken 1 hour.</p>
				<p>This treatment is available for 16 years and over, and a patch test will be required the first time you have this treatment at Birds of Beauty, or, you have had a lapse of more than 6 months, or you have had a change in your medical history.</p>
				<Link className="button cta" aria-label="Book LVL Lash Lift now" to="/book">Book now</Link>
			</div>
		</section>
		<section>
			<div className="container">
				<h2>LVL Lash Lift Advanced &ndash; &pound;50</h2>
				<p>All the great benefits of a standard lash lift, to both the top and bottom lashes. Time taken 1 hour.</p>
				<Link className="button cta" aria-label="Book LVL Lash Lift Advanced now" to="/book">Book now</Link>
			</div>
		</section>
		<section className="grey-bg">
			<div className="container">
				<h2>Lash tint &ndash; &pound;16</h2>
				<p>Fed up of your mascara running and giving you the panda eye look? &nbsp;Have no spare time in the morning? Then a lash tint might just be the treatment for you, as it will give your lashes smudge free colour for upto 6 weeks. &nbsp;</p>
				<p>For 16 years and over, a patch test will be required if you are a new client to Birds of Beauty, have had a lapse of more than 6 months, or have had a change in your medical history, including vaccinations.</p>
				<p>Time taken up to 20 minutes.</p>
				<Link className="button cta" aria-label="Book Lash tint now" to="/book">Book now</Link>
			</div>
		</section>
	</Layout>
)